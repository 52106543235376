<template>
  <div class="students checkschools">
    <div class="minw">
      <div class="students_searchtitle">
        <div class="students_searchtitle_box" @click="changeactive(1)   " :class="{ students_Active: 1 == activeindex }">
          院校查询
        </div>
        <div class="students_searchtitle_box" @click="changeactive(2)    " :class="{ students_Active: 2 == activeindex }">
          明细查询
        </div>
      </div>
      <!-- 院校查询 -->
      <div v-show="activeindex == 1">
        <searchSchool activeId="2"></searchSchool>
      </div>
      <!-- 明细查询 -->
      <div v-show="activeindex == 2">
        <el-form :model="queryParams" label-width="70px">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="年份">
                <template>
                  <el-select v-model="queryParams.particularYear" placeholder="请选择年份">
                    <el-option v-for="index in 5" :label="new Date().getFullYear() - index + 1"
                      :value="new Date().getFullYear() - index + 1" :key="index">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="批次">

                <template>
                  <el-select clearable v-model="queryParams.batchFile" placeholder="请选择批次" @change="batchFileChange">
                    <el-option v-for="item in batchList" :label="item.label" :value="item.value" :key="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="招生类型">

                <template>
                  <el-select clearable multiple collapse-tags v-model="queryParams.recruitStudentsCategory" placeholder="招生类型">
                    <el-option v-for="item in zslxListCopy" :label="item.name" :value="item.name" :key="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="首选科目">

                <template>
                  <el-select clearable v-model="queryParams.subjects" placeholder="请选择首选科目">
                    <el-option label="物理" value="物理"></el-option>
                    <el-option label="历史" value="历史"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>


          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="再选科目">

                <template>
                  <el-select clearable multiple v-model="queryParams.checkRequest" placeholder="请选择再选科目">
                    <el-option label="生物" value="生物"></el-option>
                    <el-option label="化学" value="化学"></el-option>
                    <el-option label="地理" value="地理"></el-option>
                    <el-option label="政治" value="政治"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
             <el-col :span="6">
              <el-form-item label="院校专业">
                <el-cascader  collapse-tags clearable v-model="queryParams.majornameType" :props="customProps"
                  :options="levearr" :key="majorCasckey" />
              </el-form-item>
            </el-col>
             <el-col :span="6">
              <el-form-item label="院校名称">
                <el-input v-model="queryParams.collegeName" placeholder="请输入院校名称"></el-input>
              </el-form-item>
            </el-col>



            <el-col :span="6">
              <div class="searchbtn" @click="sousuo">
                搜索
              </div>
            </el-col>
          </el-row>
        </el-form>
        <el-table :data="studentTableData" style="width:100%;margin-top:30px;border-radius:10px;border:1px solid #edebeb"
          :header-cell-style="{
          backgroundColor: '#f4f4f4',
          color: 'black',
          fontSize: '17px',
          textAlign: 'center',
        }" :cell-style="{
          textAlign: 'center',
          border: '1px solid #edebeb'
        }" v-loading="loading"  >

          <el-table-column prop="collegeCode" label="院校代号"  ></el-table-column>
          <el-table-column prop="collegeName" label="院校名称" width="90"></el-table-column>
          <el-table-column prop="majorCode" label="专业代号" ></el-table-column>
          <el-table-column prop="majorname" label="专业名称" width="100"></el-table-column>
          <el-table-column prop="professionalInstruction" label="专业简注" width="280"></el-table-column>
          <el-table-column prop="checkRequest" label="再选科目"></el-table-column>
          <el-table-column prop="batchFile" label="批次"></el-table-column>
           <el-table-column prop="subjects" label="主选科目"   ></el-table-column>
          <el-table-column prop="recruitStudentsCategory" label="招生类型"></el-table-column>
          <el-table-column prop="recruitStudentNumber" label="招生人数"></el-table-column>
          <el-table-column prop="professionalSystem" label="学制"></el-table-column>
          <el-table-column prop="tuition" label="学费"></el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize" @pagination="getList" />
      </div>
    </div>
  </div>
</template>

<script>

import searchSchool from "@/components/searchSchool.vue"
import {

  queryLevelVo
} from '@/api/professionalinvestigation.js'
import { REQqueryCollegePlanList } from "@/api/checkschools.js"
import { zslxListCopy } from "@/utils/fillingList.js";

export default {
  name: "students",
  components: {
    searchSchool
  },
  props: {},
  data() {
    return {
      activeindex: 1,
      loading:false,
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        batchFile: '本科批',
        recruitStudentsCategory: '',
        collegeName: '',
        majornameType: '',
        subjects: '',
        checkRequest: '',
        particularYear: new Date().getFullYear()
      },
        total: 0,
      batchList: [
        { label: '本科批', value: '本科批' },
        { label: '专科批', value: '专科批' },
        { label: '本科提前批A段', value: '本科提前批A段' },
        { label: '本科提前批B段', value: '本科提前批B段' },
        { label: '本科提前批C段', value: '本科提前批C段' },
        { label: '专科提前批', value: '专科提前批' },
      ],
      zslxListCopy: [
    {
      name: '普通',
      id: 2,
      show: false
    },
    {
      name: '地方专项计划',
      id: 3,
      show: false
    },
    {
      name: '国家专项计划',
      id: 6,
      show: false
    },
    {
      name: '高校专项计划',
      id: 6,
      show: false
    },
    {
      name: '中外合作',
      id: 4,
      show: false
    },
    {
      name: '校企合作',
      id: 5,
      show: false
    },
     {
      name: '优师专项',
      id: 5,
      show: false
    },
     {
      name: '公费师范生',
      id: 5,
      show: false
    },
    {
      name: '免费医学定向生',
      id: 7,
      show: false
    },
  ],
      customProps: {
        multiple: true, // 启用多选
        value: 'professionalCode', // 自定义当前数组的键值 - value
        label: 'subjectcategoriesName', // 自定义当前数组的键名 - label
        children: 'majorPlusVos', // 自定义当前数组 - children
        expandTrigger: 'hover' // 次级菜单的展开方式 - click/hover
      },
      majorCasckey: 0,
      levearr: [],
      studentTableData: [

      ]
    };
  },
  watch: {
    levearr: {
      handler() {
        this.majorCasckey++
      }
    }
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {
    this.getqueryLevelVo('本科')
  },
  methods: {
    // 批次发生变化
    batchFileChange(){
        if(this.queryParams.batchFile.includes('本科')){
            this.zslxListCopy = [
    {
      name: '普通',
      id: 2,
      show: false
    },
    {
      name: '地方专项计划',
      id: 3,
      show: false
    },
    {
      name: '国家专项计划',
      id: 6,
      show: false
    },
    {
      name: '高校专项计划',
      id: 6,
      show: false
    },
    {
      name: '中外合作',
      id: 4,
      show: false
    },
    {
      name: '校企合作',
      id: 5,
      show: false
    },
     {
      name: '优师专项',
      id: 5,
      show: false
    },
     {
      name: '公费师范生',
      id: 5,
      show: false
    },
    {
      name: '免费医学定向生',
      id: 7,
      show: false
    },
  ]
        }else if(this.queryParams.batchFile.includes('专科')){
            this.zslxListCopy = [
                {
      name: '普通',
      id: 1,
      show: false
    },
     {
      name: '校企合作',
      id: 2,
      show: false
    },
     {
      name: '定向培养军士生',
      id: 3,
      show: false
    },
     {
      name: '免费医学定向生',
      id: 4,
      show: false
    },
            ]
        }
        this.queryParams.recruitStudentsCategory = []
    },
    changeactive(index){
        this.activeindex = index;
        if(index == 2 && this.studentTableData.length ==0){
            this.getList()
        }
    },
    // 获取专业名称
    getqueryLevelVo(level) {
      const self = this
      const obj = {
        professionalLevel: level
      }
      queryLevelVo(obj).then((res) => {
        if (res.code == 200) {
          res.data.forEach(item => {
            item.majorPlusVos.forEach(itemm => {
              itemm.professionalMajorVos.forEach(itemmm => {
                self.$set(itemmm, 'subjectcategoriesName', itemmm.professionalName)
              })
              self.$set(itemm, 'subjectcategoriesName', itemm.majorplusName)
              self.$set(itemm, 'majorPlusVos', itemm.professionalMajorVos)
            })
          })
          self.levearr = res.data
        }
      })
    },
    sousuo(){
        this.queryParams.pageNum = 1
        this.queryParams.pageSize = 10
        this.getList()
    },
    // 根据明细查询招生计划
    getList() {
      let obj = { ...this.queryParams }
      this.loading = true
      obj.recruitStudentsCategory = obj.recruitStudentsCategory.join('|')
      obj.checkRequest = obj.checkRequest.join('|')
      obj.majornameType = obj.majornameType ? obj.majornameType.join('|').replace(/,/g, '') : ''
      REQqueryCollegePlanList(obj).then(res => {

        res.data.rows.forEach(item=>{
              for (const key in item) {
                if (!item[key]) {
                    item[key] = '--'
                }
              }
        })
        this.studentTableData = res.data.rows
        this.total = res.data.total
         this.loading = false
      })
    }
  },
};
</script>

<style scoped lang="scss">
.students {
  .students_searchtitle {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    border-bottom: 2px solid #12B098;

    .students_searchtitle_box {
      width: 202px !important;
      height: 40px;
      color: black;
      text-align: center;
      width: 100%;
      line-height: 40px;
      font-size: 18px;
    }

    .students_Active {
      background: #12B098;
      color: white;
    }
  }

  .searchbtn {
    width: 120px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    border-radius: 8px;
    text-align: center;
    color: white;
    background: #12B098;
  }
  ::v-deep .el-cascader .el-input__suffix{
    margin-right: 0px;
    padding: 0px !important;
    right: 4px;
    top: 0px;
}
}
</style>
